<template>
  <div class="multiple-direct-upload-input">
    <div class="alert alert-danger" v-show="isFileCountOver">
      ファイルは{{ max }}個が上限です
    </div>
    <input type="file" multiple="true" :accept="accept" @change="onChange" />
    <div v-for="(file, index) in files" :key="index">
      <input
        type="hidden"
        :value="uploadedFileValues[index]"
        :name="setting.name"
      />
      <canvas ref="canvas"></canvas>
    </div>
  </div>
</template>

<script>
import 'blueimp-canvas-to-blob';
import DirectUploader from '../lib/direct_uploader';
import { loadImageAsync, drawResizedImageToCanvas } from '../lib/image';

export default {
  name: 'MultipleDirectUploadInput',
  props: {
    accept: {
      type: String,
      default: 'image/*',
    },
    max: {
      type: Number,
      default: 3,
    },
    setting: {
      type: Object,
      default: function() {
        return {
          directUploadUrl: '',
          name: '',
        };
      },
    },
  },
  data() {
    return {
      files: [],
      uploadedFileValues: [],
    };
  },
  computed: {
    isFileCountOver() {
      return this.files.length > this.max;
    },
  },
  methods: {
    onChange(event) {
      this.files = Array.from(event.target.files);

      if (this.isFileCountOver) {
        return;
      }

      try {
        this.files.forEach(async (file, index) => {
          if (!this.accept.split(',').some(accept => file.type.match(accept))) {
            throw new Error('File does not match file');
          }

          if (file.type.includes('image')) {
            this.uploadImage(file, index);
          } else {
            this.upload(file, index);
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    async uploadImage(file, index) {
      const image = await loadImageAsync(URL.createObjectURL(file));
      const canvas = drawResizedImageToCanvas(this.$refs.canvas[index], image);
      canvas.toBlob(
        async blob => {
          blob.name = `${new Date().getTime()}.jpg`;
          this.upload(blob, index);
        },
        'image/jpeg',
        0.95
      );
    },
    async upload(blob, index) {
      const uploader = new DirectUploader(blob, this.setting.directUploadUrl);
      const uploaded = await uploader.upload();
      // console.log(uploaded);
      let values = Object.assign([], this.uploadedFileValues);
      values[index] = uploaded.signed_id;
      this.uploadedFileValues = values;
    },
  },
};
</script>

<style scoped lang="scss">
canvas {
  display: none;
}
</style>
