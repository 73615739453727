<template>
  <div class="photo-identity">
    <slot name="ready" v-if="isReady"></slot>
    <camera-player
      @take="onTake"
      v-if="cameraEnable"
      :facing-mode="setting.facingMode"
    />
    <canvas ref="preview" v-show="isPreview"></canvas>
    <button
      :class="buttonClass"
      @click.prevent="onCameraEnable"
      v-show="!cameraEnable"
    >
      {{ buttonText }}
    </button>
    <input
      type="hidden"
      :value="uploadedFileValue"
      :name="setting.name"
      v-if="uploadedFileValue.length > 0"
    />
  </div>
</template>

<script>
import 'blueimp-canvas-to-blob';
import { DirectUpload } from '@rails/activestorage';
import CameraPlayer from '../components/CameraPlayer.vue';

export default {
  name: 'PhotoIdentity',
  components: {
    CameraPlayer,
  },
  props: {
    setting: {
      type: Object,
      default: function() {
        return {
          directUploadUrl: '',
          name: '',
          facingMode: 'environment',
        };
      },
    },
  },
  data() {
    return {
      cameraEnable: false,
      photoTaken: false,
      uploadedFileValue: '',
    };
  },
  computed: {
    isReady() {
      return !this.cameraEnable && !this.photoTaken;
    },
    isPreview() {
      return !this.cameraEnable && this.photoTaken;
    },
    buttonText() {
      if (this.isPreview) {
        return '撮影しなおす';
      }

      return '撮影する';
    },
    buttonClass() {
      if (this.isPreview) {
        return 'btn btn-warning';
      }

      return 'bnt btn-primary';
    },
  },
  methods: {
    onCameraEnable() {
      this.cameraEnable = true;
    },
    onTake(video) {
      this.cameraEnable = false;
      this.photoTaken = true;
      const canvas = this.$refs.preview;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext('2d')
        .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      this.upload(canvas);
    },
    upload(canvas) {
      canvas.toBlob(
        blob => {
          blob.name = `${new Date().getTime()}.jpg`;
          const upload = new DirectUpload(blob, this.setting.directUploadUrl);
          upload.create((error, blob) => {
            if (error) {
              throw new Error(error);
            }

            console.log(blob);

            this.uploadedFileValue = blob.signed_id;
            this.$emit('attached');
          });
        },
        'image/jpeg',
        0.95
      );
    },
  },
};
</script>

<style scoped lang="scss">
.photo-identity {
  canvas {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto auto 1rem;
  }
}
</style>
